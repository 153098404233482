<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="姓名:" label-width="44px" class="w-200">
					<el-input v-model="table.where.name" placeholder="请输入姓名" clearable />
				</el-form-item>
				<el-form-item label="手机号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.phone" placeholder="请输入手机号" clearable />
				</el-form-item>
				<el-form-item label="车牌号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.car_number" placeholder="请输入车牌号" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="58px" class="w-200 mr-20">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="(table.where = { m_type: 1, status: 5 }) && $refs.table.reload()">重置</el-button>
					<download v-if="permission.includes('sys:cancel_account:export')" style="margin-left: 0"
						class="ele-btn-icon ml-20" label="导出" url="driver/exportExcelCancel"
						:params="exportOrderParams()" />
				</el-form-item>

			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />

					<el-table-column prop="true_head" label="头像" show-overflow-tooltip min-width="80">
						<template slot-scope="{row}">
							<el-image style="width:25px;height:25px;" :src="row.true_head" fit="cover"
								:preview-src-list="[row.true_head]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="true_name" label="真实姓名" show-overflow-tooltip />
					<el-table-column prop="sex" label="性别" show-overflow-tooltip>
						<template slot-scope="{row}">{{ row.sex == 0 ? '男' : '女' }}</template>
					</el-table-column>
					<el-table-column prop="driver_type" label="司机类型" show-overflow-tooltip >
						<template slot-scope="{row}">{{row.driver_type==1?'租车':(row.driver_type==2 ? '车主':'/')}}</template>
					</el-table-column>
					<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="100" />
					<el-table-column prop="driver_area" label="所属地区" show-overflow-tooltip min-width="220" />
					<el-table-column prop="car_color" label="车辆颜色" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_name" label="车辆类型" show-overflow-tooltip min-width="120" />
					<el-table-column label="注册时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="最近登录时间" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.active_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="账号注销时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.cancel_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="order_total" label="累计接单量(笔)" width="120px" show-overflow-tooltip />
					<!--					<el-table-column label="操作" width="180px" align="center" :resizable="false" fixed="right">-->
					<!--						<template slot-scope="{row}">-->
					<!--							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>-->
					<!--						</template>-->
					<!--					</el-table-column>-->
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看司机详情 -->
		<el-dialog v-dialogDrag title="查看司机详情" :visible.sync="dialogTableVisibleCheck" width="800px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" label-width="170px">
					<div style="display: flex;">
						<div style="padding: 0 40px 0 30px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="今日取消订单量:" prop="money">
								{{ checkForm.today_cancel }}
							</el-form-item>
							<el-form-item label="本月累计取消订单量:" prop="invite_money">
								{{ checkForm.thisMon_cancel }}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{ checkForm.month_order_total }}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{ checkForm.month_order_price }}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{ checkForm.month_order_earning }}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{ checkForm.month_order_fee }}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{ checkForm.month_order_promote }}
							</el-form-item>
							<el-form-item label="司机姓名：" prop="name">
								{{ checkForm.true_name }}
							</el-form-item>
							<el-form-item label="头像：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.true_head"
									fit="cover" :preview-src-list="[checkForm.true_head]"></el-image>
							</el-form-item>
							<el-form-item label="是否载客" prop="phone">
								{{ checkForm.is_carry }}
							</el-form-item>
							<el-form-item label="手机号：" prop="phone">
								{{ checkForm.phone }}
							</el-form-item>
							<el-form-item label="所属区域：">
								{{ checkForm.driver_area }}
							</el-form-item>
							<el-form-item label="累计接单收益：" prop="money">
								{{ checkForm.money }}
							</el-form-item>
							<el-form-item label="诚信度：" prop="invite_money">
								{{ checkForm.credit }}
							</el-form-item>
							<el-form-item label="累计在线时长：" prop="total_time">
								{{ checkForm.total_time }}分钟
							</el-form-item>
							<el-form-item label="最近的登录时间：" prop="money">
								{{ checkForm.active_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="注册时间：" prop="invite_money">
								{{ checkForm.create_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="性别：">
								{{ checkForm.sex == 0 ? '男' : '女' }}
							</el-form-item>
							<el-form-item label="真实姓名：">
								{{ checkForm.true_name }}
							</el-form-item>
						</div>
						<div>
							<el-form-item label="身份证号：" prop="idcard">
								{{ checkForm.idcard }}
							</el-form-item>
							<el-form-item label="支付宝账号：" prop="idcard">
								{{ checkForm.ali_number }}
							</el-form-item>
							<el-form-item label="车牌号：" prop="car_number">
								{{ checkForm.car_number }}
							</el-form-item>
							<el-form-item label="手持身份证照片：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_hand"
									fit="cover" :preview-src-list="[checkForm.idcard_hand]"></el-image>
								<!--								<img :src="checkForm.idcard_hand" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="身份证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_front"
									fit="cover" :preview-src-list="[checkForm.idcard_front]"></el-image>
								<!--								<img :src="checkForm.idcard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="身份证反面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_back"
									fit="cover" :preview-src-list="[checkForm.idcard_back]"></el-image>

								<!--								<img :src="checkForm.idcard_back" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="驾驶证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.jiacard_front"
									fit="cover" :preview-src-list="[checkForm.jiacard_front]"></el-image>

								<!--								<img :src="checkForm.jiacard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="行驶证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.xingcard_front"
									fit="cover" :preview-src-list="[checkForm.xingcard_front]"></el-image>

								<!--								<img :src="checkForm.xingcard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="车辆正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.car_front"
									fit="cover" :preview-src-list="[checkForm.car_front]"></el-image>

								<!--								<img :src="checkForm.car_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="从业资格证：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.taxi_operation"
									fit="cover" :preview-src-list="[checkForm.taxi_operation]"></el-image>
								<!--								<img :src="checkForm.taxi_operation" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="道路运输证：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.road_transport"
									fit="cover" :preview-src-list="[checkForm.road_transport]"></el-image>
								<!--								<img :src="checkForm.road_transport" width="70"   height="70" />-->
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 查看出车记录 -->
		<el-dialog v-dialogDrag title="查看出车记录" :visible.sync="dialogTableVisibleCarRecords" class="records"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<div style="margin-left:28px">
					<div><span class="foWei6">司机姓名：</span>{{ driverData.name }} </div>
					<div><span class="foWei6">类型：</span>{{ driverData.type }} </div>
					<div><span class="foWei6">手机号：</span>{{ driverData.phone }} </div>
					<div><span class="foWei6">地区：</span>{{ driverData.area }} </div>
				</div>
				<div class="greenLine"></div>
				<div class="statist" style="margin-left: 30px;">出车统计</div>
				<div id="main" style="width:100%;height:300px;"></div>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
import {
	mapGetters
} from "vuex";
// import uploadImage from '@/components/uploadImage'
import download from '@/components/Download/index'
export default {
	name: "SysUser",
	components: {
		// uploadImage,
		download
	},
	data() {
		return {
			table: {
				url: '/driver/cancel_log',
				where: {

				}
			}, // 表格配置
			table1: {
				url: '/driver/driver_check_log',
				where: {}
			}, // 表格配置
			changePro: false,
			changeCity: false,
			changeArea: false,
			jd_changePro: false,
			jd_changeCity: false,
			jd_changeArea: false,
			choose: [], // 表格选中数据
			showEdit: false, // 是否显示表单弹窗
			dialogVisiblePlans: false,
			blackReason: {},
			editForm: {}, // 表单数据
			editRules: { // 表单验证规则
				phone: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}, {
					pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
					message: '请输入正确的手机号',
					trigger: 'blur',
				}],
				name: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				pid: [{
					required: true,
					message: '请选择省份',
					trigger: 'change'
				}],
				sex: [{
					required: true,
					message: '请选择性别',
					trigger: 'change'
				}],
				idcard: [{
					required: true,
					message: '请输入身份证号',
					trigger: 'blur'
				},
				{
					pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
					message: '请输入正确的身份证号',
					trigger: 'blur'
				}
				],
				car_number: [{
					required: true,
					message: '请输入车牌号',
					trigger: 'blur'
				}],
				car_persons: [{
					required: true,
					message: '请输入车座数量',
					trigger: 'blur'
				}]
			},
			provArr: [],
			cityArr: [],
			districtArr: [],
			jd_provArr: [],
			jd_cityArr: [],
			jd_districtArr: [],
			checkForm: {},
			recordsList: [],
			startTime: '',
			endTime: '',
			driverData: {},
			dialogTableVisibleEdit: false,
			dialogTableVisibleCheck: false,
			dialogTableVisibleRecords: false,
			dialogTableVisibleCarRecords: false,
			pageSize: 10,
			pageNumber: 1,
			recordsTotal: 1000,
			recordsShow: false,
			recordsId: '',
			loading: true,
			pid: '',
			cid: '',
			aid: '',
			tableend_time: '',
			tablestart_time: '',
		}
	},
	created() {


		this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
			this.provArr = data
			this.jd_provArr = data
		})

	},
	computed: {
		...mapGetters(["permission"]),
	},
	mounted() { },
	methods: {

		// 导出数据参数
		exportOrderParams() {
			//console.log("this.permission")
			//console.log(this.permission)
			const query = this.table.where
			// const params = this.params
			const selectionIds = this.choose.map(item => {
				return item.id
			})

			return {
				ids: selectionIds,
				...query
			}
		},
		dropClick(command, row) {
			if (command === 'edit') { // 查看用户信息
				this.edit()
			} else if (command === 'remove') { //删除
				this.remove()
			} else if (command === 'out_list') { //出车记录
				this.handleCarRecords(row)
			} else if (command === 'check_list') { //检测记录
				this.handleRecords(row)
			} else if (command === 'edit') { //修改用户信息
				this.edit(row)
			} else if (command === 'forlimit') { //限号
				this.forlimit(row)
			} else if (command === 'limitWithDraw') { //限制提现
				this.limitWithDraw(row)
			} else if (command === 'openWithDraw') { //恢复提现
				this.openWithDraw(row)
			}
			if (command === 'blackData') { //拉入黑名单
				this.dialogVisiblePlans = true
				this.blackReason = row
			}
		},

		/**
		 *选择省
		 **/
		handleChangeProv(e) {
			/** 获取被选省省份的pid**/
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.table.where.cid = ''
				this.table.where.aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
			} else {
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
		},
		handleChangeProv1(e) {
			/** 获取被选省省份的pid**/
			this.changePro = true
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.editForm.cname = ''
				this.editForm.aname = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity1(e) {
			if (e) {
				/** 获取被选市的cid**/
				this.changeCity = true
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
			} else {
				this.editForm.pname = ''
				this.editForm.aname = ''
			}
		},
		handleDis() {
			this.changeArea = true
		},

		/**
		 *修改用户信息
		 **/
		handleEdit(row) {
			this.dialogTableVisibleEdit = true
			this.editForm = row;

			if (this.editForm.pid > 0) {
				this.$http.post('/common/city_list', {
					pid: this.editForm.pid
				}).then(res => {
					let cdata = JSON.parse(res.data)
					this.cityArr = cdata
				})
			}
			if (this.editForm.cid > 0) {
				this.$http.post('/common/area_list', {
					cid: this.editForm.cid
				}).then(res => {
					let adata = JSON.parse(res.data)
					this.districtArr = adata
				})
			}

			if (this.editForm.jd_pid > 0) {
				this.$http.post('/common/city_list', {
					pid: this.editForm.jd_pid
				}).then(res => {
					let cdata = JSON.parse(res.data)
					this.jd_cityArr = cdata
				})
			}
			if (this.editForm.jd_cid > 0) {
				this.$http.post('/common/area_list', {
					cid: this.editForm.jd_cid
				}).then(res => {
					let adata = JSON.parse(res.data)
					this.jd_districtArr = adata
				})
			}
		},
		/**
		 *查看用户信息
		 **/
		handlecheck(row) {
			this.dialogTableVisibleCheck = true
			// if(row.is_carry==1){
			//   row.is_carry='载客'
			// }else{
			//   row.is_carry='空闲'
			// }
			// if(row.status==2){
			//   row.status='正常'
			// }else(
			//   row.status='待完善信息'
			// )
			this.checkForm = row;
		},
		/**
		 *检测记录  pageSize（每页的条数），pageNumber（页码）
		 **/
		handleRecords(row) {
			this.dialogTableVisibleRecords = true
			this.recordsId = row.id
			this.recordsUId = row.uid
			this.getRecords(row.uid)

		},
		getRecords(id) {
			this.recordsList = [];
			var formData = {
				id: id,
				pageSize: this.pageSize,
				pageNumber: this.pageNumber,
				start_time: this.startTime,
				end_time: this.endTime

			}
			this.$http.post('/driver/test_log', formData).then(res => {
				// var data = JSON.parse(res.data)
				var data = res.data;
				this.recordsTotal = data.total
				if (data.total == 0 || data.total == 'null') {
					this.recordsShow = false
				} else {
					this.recordsShow = true
				}
				if (data.data.length) {
					this.recordsList = data.data

				}
			})
		},
		/**
		 *查询
		 **/
		searchRecords() {
			this.getRecords(this.recordsUId)
		},
		/**
		 *分页
		 **/
		handleCurrentChange(e) {
			//console.log(e)
			this.pageNumber = e
			this.getRecords(this.recordsId)
		},
		/**
		 *查看出车记录
		 **/
		handleCarRecords(row) {
			this.dialogTableVisibleCarRecords = true
			const loading = this.$loading({
				lock: true,
				target: document.querySelector(".records")
			});
			this.$http.post('/driver/driver_log', {
				id: row.id
			}).then(res => {
				loading.close()
				let data = JSON.parse(res.data)
				this.driverData = data.driver_data
				let xData = data.x_datas
				let yData = data.y_datas
				this.$nextTick(() => {
					let dom = document.getElementById('main')
					let myChart = this.$echarts.init(dom);
					let option;
					option = {

						toolbox: {
							show: true,
							feature: {
								saveAsImage: { //保存图片
									show: true
								},
							}
						},
						xAxis: {
							type: 'category',
							data: xData
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							data: yData,
							type: 'line',
							itemStyle: {
								normal: {
									color: '#009F95',
									lineStyle: {
										color: '#009F95'
									}
								}
							},
						}],

					};
					option && myChart.setOption(option);
				})
			})
		},

		/**
		 * 显示编辑
		 */
		edit(row) {
			this.$http.get('/driver/info?id=' + row.id).then(res => {
				if (res.data.code === 0) {
					this.editForm = res.data.data;
					this.showEdit = true;
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(e => {
				this.$message.error(e.message);
			});
		},
		/**
		 * 保存编辑
		 */
		save() {
			this.$refs['editForm'].validate((valid) => {
				if (valid) {
					const loading = this.$loading({
						lock: true
					});
					if (this.changePro == true) {
						this.editForm.pid = this.editForm.pname
						this.changePro = false
					}
					if (this.changeCity == true) {
						this.editForm.cid = this.editForm.cname
						this.changeCity = false
					}
					if (this.changeArea == true) {
						this.editForm.aid = this.editForm.aname
						this.changeArea = false
					}
					//console.log(this.jd_changePro)
					if (this.jd_changePro == true) {
						this.editForm.areas = this.editForm.jd_aname;
					}

					this.$http.post('/driver/edit', this.editForm).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.showEdit = false;
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
							this.dialogTableVisibleEdit = false
							this.changePro = false
							this.changeCity = false
							this.changeArea = false
							this.jd_changePro = false
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				} else {
					return false;
				}
			});
		},
		/**
		 * 刪除(批量刪除)
		 */
		remove(row) {
			//console.log(row)
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要删除选中的司机吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/driver/delete', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/delete', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			}
		},
		blackData() {
			const loading = this.$loading({
				lock: true
			});
			this.$http.post('/driver/forblack', {
				id: this.blackReason.id,
				black_data: this.blackReason.black_data
			}).then(res => {
				loading.close();
				if (res.data.code === 0) {
					this.$message({
						type: 'success',
						message: res.data.msg
					});
					this.dialogVisiblePlans = false;
					this.$refs.table.reload();
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(e => {
				loading.close();
				this.$message.error(e.message);
			});
		},
		forlimit(row) {

			this.$confirm('确定把该司机限号吗?', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/forlimit', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		// 限制提现
		limitWithDraw(row) {
			this.$confirm('确定把司机限制提现吗？', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/update_field', {
					id: row.id,
					key: 'is_tx',
					value: 0
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		// 限制提现
		openWithDraw(row) {
			this.$confirm('确定把司机恢复提现吗？', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/update_field', {
					id: row.id,
					key: 'is_tx',
					value: 1
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		/* 导出数据Excel */
		// exportExcel() {
		//   let info = JSON.parse(JSON.stringify(this.table.where));
		//   // let name = this.table.where.name;
		//   // let phone = this.table.where.phone;
		//   // let cid = this.table.where.cid;
		//   // let pid = this.table.where.pid;
		//   // let area_id = this.table.where.area_id;
		//   // let page = this.table.where.area_id;
		//   //console.log('user data export')
		//   this.$http
		//       // .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
		//       .get("/driver/exportExcel", info)
		//       .then((res) => {
		//         let data = res.data;
		//         if (data.code == 0) {
		//           // 下载文件
		//           window.location.href = data.data;
		//           this.$message({
		//             type: "success",
		//             message: "导出成功",
		//           });
		//         } else {
		//           this.$message.error("导出失败");
		//         }
		//       })
		// },


		handleChangeProv2(e) {
			/** 获取被选省省份的pid**/
			let pid = ''
			this.jd_provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.jd_cityArr = data
				/** 选择省份清空市县**/
				this.editForm.jd_cname = ''
				this.editForm.jd_aname = ''
				this.editForm.jd_aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity2(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.jd_cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.jd_districtArr = data
					/** 选择省份清空县**/
					this.editForm.jd_aname = ''
					this.editForm.jd_aid = ''
				})
			} else {
				this.editForm.jd_pname = ''
				this.editForm.jd_aname = ''
				this.editForm.jd_aid = ''
			}
		},
		handleDis2(e) {
			this.xiu = true;
			this.jd_changePro = true
			//console.log(e)

			var editaid = [];
			// 多选
			this.jd_districtArr.forEach(function (item) {
				e.forEach(function (i) {
					if (item.aid == i || item.name == i) {
						editaid.push(item.aid)
					}
				})
			})
			//console.log(editaid)
			this.$set(this.editForm, this.editForm.jd_aname, editaid)
			this.editForm.jd_aname = editaid
		},
		birthdayChange1() {
			//console.log(this.tableend_time)
			this.startTime = this.tableend_time

		},
		birthdayChange2() {
			//console.log(this.tablestart_time)
			this.endTime = this.tablestart_time;
		},

	}
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
	width: 100%;
}

/deep/.el-dialog {
	margin-top: 40px !important;
}
</style>
